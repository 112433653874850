import React, { useState, useRef } from 'react';
import { Typography, TextField, Button, Paper } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import useStyles from './styles';

import { commentPost, deleteComments } from '../../actions/posts';

const CommentSection = ({ post }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [comments, setComments] = useState(post?.comments);
  const [comment, setComment] = useState('');
  const user = JSON.parse(localStorage.getItem('profile'));
  const commentsRef = useRef();

  const handleSubmit = async () => {
    const finalComment = `${user?.result.name}: ${comment}`;
    const userId = user?.result._id || user?.result?.sub;
    const newComments = await dispatch(commentPost(finalComment, post._id, userId))

    setComments(newComments);

    setComment('');

    commentsRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  const deleteComment = async (index) => {
    const filteredComments = comments.filter((_,i) => i !== index)
    const updatedComments = await dispatch(deleteComments(filteredComments, post._id));
    setComments(updatedComments);
  }

  return (
    <div>
      <div className={classes.commentsOuterContainer}>
        <div className={classes.commentsInnerContainer}>
          <Typography gutterBottom variant="h6">Comments</Typography>
          {comments.map((comment, index) => (
            <Paper key={index} elevation={3} style={{margin: '10px', padding: '5px' }}>
              <Typography gutterBottom varaint="subtitle1">
                <strong>{comment.comment.split(': ')[0]}:</strong>
                {comment.comment.split(':')[1]}
              </Typography>
              {(user?.result.sub === comment.userId || user?.result._id === comment.userId) && (
                <Button fullWidth variant="contained" color="secondary" key={index} onClick={() => deleteComment(index)}>
                  Delete
                </Button>
              )}
            </Paper>
          ))}
          <div ref={commentsRef} />
        </div>
        {user?.result && (
        <div style={{ width:'70%' }}>
          <Typography gutterBottom variant="h6">Write a Comment</Typography>
          <TextField 
            fullWidth
            minRows={4}
            variant="outlined"
            label="Comment"
            multiline
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          <Button color="primary" style={{ marginTop: '10px'}} fullWidth disabled={!comment} variant="contained" onClick={handleSubmit}>
            Comment
          </Button>

        </div>
        )}
      </div>
    </div>
  )

}

export default CommentSection;