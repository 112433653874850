import { FETCH_ALL, CREATE, UPDATE, DELETE, LIKE, FETCH_BY_SEARCH, START_LOADING, END_LOADING, FETCH_POST, COMMENT, DELETE_COMMENTS, FETCH_BY_CREATOR } from '../constants/actionTypes';
import * as api from '../api';

// Action Creators
export const getPosts = (page) => async (dispatch) => {

  try {
    dispatch({type: START_LOADING });
    const { data } = await api.fetchPosts(page);
    dispatch({type: FETCH_ALL, payload : data});
    dispatch({type: END_LOADING });
    
  } catch (error) {
    console.log(error);
  }
}

export const getPost = (id) => async (dispatch) => {

  try {
    dispatch({type: START_LOADING });
    const { data } = await api.fetchPost(id);
    dispatch({type: FETCH_POST, payload : data});
    dispatch({type: END_LOADING });
    
  } catch (error) {
    console.log(error);
  }
}

export const getPostsBySearch = (searchQuery) => async (dispatch) => {
  try {
    dispatch({type: START_LOADING });
    const { data: { data } } = await api.fetchPostsBySearch(searchQuery);

    dispatch({type: FETCH_BY_SEARCH, payload : data});
    dispatch({type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
}

export const getPostsByCreator = (name) => async (dispatch) => {
  try {
    dispatch({type: START_LOADING });
    const { data } = await api.fetchPostsByCreator(name);

    dispatch({type: FETCH_BY_CREATOR, payload : data});
    dispatch({type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
}

export const createPost = (post, history) => async (dispatch) => {
  try {
    dispatch({type: START_LOADING });
    const { data } = await api.createPost(post);

    dispatch({ type: CREATE, payload:data})

    dispatch({type: END_LOADING });
    history.push(`/posts/${data._id}`);
    
  } catch (error) {
    console.log(error);
  }
}

export const updatePost = (id, post) => async (dispatch) => {
  try {
    const {data} = await api.updatePost(id, post)

    dispatch({ type: UPDATE, payload:data})

  } catch (error) {
    console.log(error);
  }
}

export const deletePost = (id) => async (dispatch) => {
  try {
    await api.deletePost(id);
    dispatch({ type: DELETE, payload: id })
    
  } catch (error) {
    console.log(error);
  }
}

export const likePost = (id) => async (dispatch) => {
  try {
    const { data } = await api.likePost(id);

    dispatch({type: LIKE, payload: data})
  } catch (error) {
    
  }
}

export const commentPost = (comment, id, userId) => async (dispatch) => {
  try {
    const { data } = await api.comment(comment, id, userId);

    dispatch({ type: COMMENT, payload: data })

    return data.comments;

  } catch (error) {
    console.log(error);
  }
}

export const deleteComments = (comments, id) => async (dispatch) => {
  try {
    const { data } = await api.deleteComment(comments, id);

    dispatch({ type: DELETE_COMMENTS, payload: data })

    return data.comments;

  } catch (error) {
    console.log(error);
  }
}