import React, { useEffect } from 'react'
import { Paper, Typography, CircularProgress, Divider } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useParams, Link } from 'react-router-dom';

import { getPost, getPostsBySearch } from '../../actions/posts';
import useStyles from './styles';
import Post from '../Posts/Post/Post';
import CommentSection from './CommentSection';

const PostDetails = () => {
  const { post, posts, isLoading } = useSelector((state) => state.posts);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getPost(id));
  }, [id, dispatch])

  useEffect(() => {
    if (post) {
      dispatch(getPostsBySearch({ search: 'none', tags: post?.tags?.join(',') }));
    }

  },[post,dispatch])

  if(!post) return null;

  //const openPost = (_id) => history.push(`/posts/${_id}`);

  if(isLoading) {
    return (<Paper elevation={6} className={classes.loadingPaper}>
      <CircularProgress size="7em" />
    </Paper>
    );
  }

  console.log(posts);
  const recommendedPosts = posts?.filter(({ _id }) => _id !== post._id )

  return (
    <Paper style={{padding: '20px', borderRadius: '15px' }} elevation={6} >
      <div className={classes.card} >
        <div className={classes.section}>
          <Typography variant="h3" component="h2">{post.title}</Typography>
          <Typography gutterBottom variant="h6" component="h2" color="textSecondary">{post.tags.map((tag, index) => (
            <Link to={`/tags/${tag}`} style={{ textDecoration: 'none', color: '#3f51b5' }} key={index}>
              {`#${tag} `}
            </Link>
            ))}</Typography>
          <Typography gutterBottom variant="body1" component="p" >{post.message}</Typography>
          <Typography variant="h6">
            Created By: 
            <Link to={{ pathname:`/creators/${post.creator}`, state:{username:`${post.name}`} }}  style={{ textDecoration: 'none', color: '#3f51b5' }} >
              {` ${post.name}` }
            </Link>
            </Typography>
          <Typography variant="body1">{ moment(post.createdAt).fromNow() }</Typography>
          <Divider style={{ margin: '20px 0' }} />
          <CommentSection post={post} />
          <Divider style={{ margin: '20px 0' }} />

        </div>
        <div className={classes.imageSection}>
          <img className={classes.media} src={post.selectedFile || 'https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png'} alt={post.title} />
        </div>
      </div>
      {Boolean(recommendedPosts?.length) && (
        <div className={classes.section}>
          <Typography gutterBottom variant="h5">You might also like:</Typography>
          <Divider />
          <div className={classes.recommendedPosts}>
            {recommendedPosts?.map(({ title, message, name, likes, selectedFile, _id, tags, createdAt}) => (
              <div style={{ margin: '15px' }} key={_id} >
                <Post setCurrentId={_id} post={ {title, name, message, _id, selectedFile, likes, createdAt }} recommended={true} />
              </div>
            ))}
          </div>
        </div>
      )}
    </Paper>
  )
}

export default PostDetails;