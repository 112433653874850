import React, { useEffect } from 'react';
import { useParams, useLocation, withRouter } from 'react-router-dom';
import { Typography, CircularProgress, Grid, Divider } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import Post from '../Posts/Post/Post';
import { getPostsByCreator, getPostsBySearch } from '../../actions/posts';

const PostByCreatorOrTag = (props) => {
  const { name } = useParams();
  const dispatch = useDispatch();
  const { posts, isLoading } = useSelector((state) => state.posts);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith('/tags')) {
      dispatch(getPostsBySearch({ tags: name }));
    } else {
      dispatch(getPostsByCreator(name));
    }
  }, [name, dispatch, location]);

  if (!posts.length && !isLoading) return 'No posts';

  return (
    <div>
      <Typography variant="h2">{location.state?.username ? location.state.username : name}</Typography>
      <Divider style={{ margin: '20px 0 50px 0' }} />
      {isLoading ? <CircularProgress /> : (
        <Grid container alignItems="stretch" spacing={3}>
          {posts?.map((post) => (
            <Grid key={post._id} item xs={12} sm={12} md={6} lg={3}>
              <Post post={post} recommended={true}/>
            </Grid>
          ))}
        </Grid>
      )}
    </div>
  );
};

export default withRouter(PostByCreatorOrTag);